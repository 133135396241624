/* .truncate-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

* {
  background-color: "rgb(245, 245, 245)";
}
